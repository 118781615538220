import { useClientStore } from '../store/client'

export default defineNuxtRouteMiddleware((to) => {
  const client = useClientStore()

  if (
    (to.name === 'complete-your-account' && !client.onboardingWizard) ||
    (to.name === 'get-started' && client.onboardingCompleted && client.onboarding?.first_job_added) ||
    (to.name === 'dashboard' && !client.onboardingCompleted)
  ) {
    return client.redirect()
  }
})
